html,
body {
  margin: 0;
  height: 100%;
}

.body {
  margin-top: 5px;
}

/* #root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
} */

.App {
  box-sizing: border-box;
  background-color: #fafbfc;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tapistree-logo {
  font-weight: 600;
  background: linear-gradient(to right, #516bfa, #009bfa);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
} */

.icon-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.emoji-icon {
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  font-size: 1.9em;
}

.add-strip-icon {
  font-size: 2em;
}

.emoji-icon-sm {
  display: flex;
  align-items: flex-start;
  font-size: 24px;
}

.emoji-tiny {
  font-size: 16px;
}

.emoji-icon-white {
  display: flex;
  align-items: flex-start;
  margin-top: -15px;
  font-size: 1.5em;
  color: white;
  filter: brightness(0) invert(1) saturate(0%) contrast(150%);
}

.active-icon {
  /* Styles for the active icon, e.g., increased size */
  transform: scale(1.17);
  transition: transform 0.3s ease;
}

.heading {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.card-sm {
  padding: 0px;
  width: 100%;
  max-width: 280px;
  gap: 10px;
}

.card {
  padding: 0px;
  width: 100%;
  max-width: 300px;
  gap: 10px;
}

.card-md {
  padding: 0px;
  width: 310px;
  /* max-width: 350px; */
  gap: 10px;
}

.card-md-lg {
  padding: 0px;
  width: 350px;
  /* max-width: 350px; */
  gap: 10px;
}

.card-lg {
  padding: 0px 5px 0px 0px;
  width: 100%;
  max-width: 450px;
  gap: 10px;
}

.card-xl {
  padding: 0px 5px 0px 0px;
  width: 100%;
  min-width: 338px;
  max-width: 450px;
  gap: 10px;
}

.card-full {
  width: 100%;
  padding-right: -5px;
}

@media (max-width: 767px) {
  .card-lg {
    width: 100%;
  }
}

.card-yt {
  padding: 0px 5px 0px 0px;
  width: 98%;
  max-width: 800px;
  gap: 10px;
}

.card-polaroid {
  padding: 0px;
  width: 95%;
  max-width: 330px;
  min-width: 330px;
  /* padding-left: 15px; */
  gap: 10px;
}

.card-book {
  padding: 0px;
  width: 100%;
  min-width: 240px;
  max-width: 280px;
  gap: 10px;
}

.card-container-scroll {
  display: flex;
  max-height: 800px;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-container-scroll-twitter {
  display: flex;
  max-height: 800px;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-container-scroll-tall {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-height: 515px;
  /* max-width: 100%; */
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-container-scroll-tall-vertical {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-height: 515px;
  /* max-width: 100%; */
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-container-carousel {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-content: flex-start;
  width: 100%;
  max-height: 600px;
  gap: 10px;
  padding-top: 10px;
}

.navbar a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  padding: 10px;
  font-size: 16px;
  color: slategray;
  text-align: center;
  text-decoration: none;
}

.video-responsive {
  /* overflow: hidden; */
  height: 100%;
  width: 100%;
  padding-bottom: 56.25%;
  /* position: relative; */
  /* height: 0; */
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.repo-language {
  display: inline-flex;
  align-items: center;
  /* border: 1px solid black; */
  font-size: 14px;
  margin: 0px 14px 0px 0px;
  color: var(--color-fg-muted);
}

:root {
  --color-primer-border-contrast: rgba(27, 31, 36, 0.1);
}

.repo-language-color {
  position: relative;
  /* top: 1px; */
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid var(--color-primer-border-contrast);
  border-radius: 50%;
  margin-right: 5px;
}

.language-text {
  font-size: 14px;
}

.App-link {
  color: #61dafb;
}

.gradient-text {
  background: linear-gradient(to right, #616bfa, #009bfa);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
}

.gradient-text-gray {
  background: linear-gradient(to right, #4d5054, #898e94);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
}

.phone-frame {
  width: 320px;
  height: 647px;
  border: 15px solid black;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  /* overflow: hidden;
  transform: scale(0.5);
  transform-origin: 0 0; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cl-userButton-root,
.cl-userButtonBox,
.cl-userButtonTrigger,
.cl-avatarBox,
.cl-avatarImage,
.cl-userButtonAvatarImage {
  height: 80px;
  width: 80px;
  border-radius: 80px;
  margin-left: auto;
  margin-right: auto;
}

.cl-avatarBox {
  border: 2px solid white;
}

.cookie-banner {
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  padding: 10px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 00px;
  z-index: 300;
}

.preview {
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  width: 340px;
  height: 647px;
  border: 5px solid black;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.preview::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
  width: 340px;
  height: 647px;
  border: 5px solid black;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.base-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.circle-red {
  border: 1px solid #eb4b42;
  background-color: #ff5f56;
}

.circle-yellow {
  border: 1px solid #eaa81a;
  background-color: #febc2e;
}

.circle-green {
  border: 1px solid #14b42c;
  background-color: #28c840;
}

.golden-text {
  background: linear-gradient(
    45deg,
    #a67c00,
    #bf9b30,
    #ffcf40,
    #bf9b30,
    #a67c00
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  display: inline-block;
}

.silver-text {
  background: linear-gradient(
    45deg,
    #c0c0c0,
    #d1d1d1,
    #e6e6e6,
    #d1d1d1,
    #c0c0c0
  );
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  display: inline-block;
}

.react-tweet-theme {
  --tweet-body-font-size: 1rem !important;
  --tweet-container-margin: 0 !important;
}

/* Blur profile pic style background */
.bgphoto::before {
  content: "";
  box-sizing: border-box;
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0px;
  background-image: var(--user-profile-photo);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.25;
  filter: blur(50px);
  z-index: -1; /* Ensure it's behind the content */
}

.bgphoto::after {
  content: "";
  box-sizing: border-box;
  position: fixed;
  width: 90%;
  top: 0px;
  height: 100%;
  background-image: var(--user-profile-photo);
  /* background-size: 512px 512px; */
  background-repeat: repeat;
  opacity: 0.04;
  mix-blend-mode: overlay;
  z-index: -1; /* Ensure it's behind the content */
}
